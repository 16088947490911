import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  CircularProgress,
  Container,
  Box,
  Chip,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Popover,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WorkTwoToned from "@mui/icons-material/WorkTwoTone";
import CustomButton from "../ButtonWrapper";
import {
  Capability,
  assignToUser,
  getInviteLink,
  getAcceptanceDetailsByGigIdAndUserId,
  getGigCapabilitiesByGigId,
  getUserAddress,
} from "@gogig-in/gogig-clients";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useAuthContext } from "../../providers/AuthProvider";
import MultipleCardsComponent from "../vendorDetail/userTaskDetails";
import MDEditor from "@uiw/react-md-editor";
import CustomSnackbar from "../operationRolePages/CustomSnackbar";
import { Link as LinkIcon } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { userRoles } from "../../types/userRoles";
import SetUserAddressPopup from "../vendorDetail/SetUserAddressPopup";

const GigStatus: React.FC = () => {
  const { gigId } = useParams<{ gigId?: string }>();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { authHeader } = useAuthContext();

  const location = useLocation();
  const pageMode = location.state ? location.state.mode : null;
  const [gigDetails, setGigDetails] = useState<any>(null);
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  const [userAddress, setUserAddress] = useState<any>(null);
  const [addressError, setAddressError] = useState(false);
  const [gigCapabilities, setGigCapabilities] = useState<Capability[]>([]);
  const { userDetails } = useAuthContext();
  const userRole = userDetails?.role;
  const [inviteLink, setInviteLink] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [supervisor, setSupervisor] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const handleDialogClose = (type: string) => {
    setOpenAddressDialog(false);
  };

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        if (authHeader) {
          const response = await getUserAddress(authHeader);
          setUserAddress(response.data);
        }
      } catch (error) {
        console.error("Error fetching user address", error);
      }
    };
    fetchUserAddress();
  }, [authHeader]);

  useEffect(() => {
    const fetchInviteLink = async () => {
      try {
        if (authHeader && gigId) {
          const response: any = await getInviteLink(authHeader, gigId);
          setInviteLink(response.data);
        }
      } catch (error) {
        console.error("Error fetching user address", error);
      }
    };
    fetchInviteLink();
    if (userRole === userRoles.supervisor) {
      setSupervisor(true);
    }
  }, [authHeader, gigId, userRole]);

  useEffect(() => {
    const fetchCapabilityData = async () => {
      try {
        if (gigId) {
          const res: any = await getGigCapabilitiesByGigId(gigId);
          setGigCapabilities(res.data);
        }
      } catch (e) {
        console.error("Something went wrong", e);
      }
    };
    fetchCapabilityData();
  }, [gigId]);

  const capabilityNames = gigCapabilities
    .map((c) => c.capabilityName)
    .join(", ");

  useEffect(() => {
    const fetchGigDetails = async () => {
      try {
        if (authHeader && gigId) {
          const response: any = await getAcceptanceDetailsByGigIdAndUserId(
            authHeader,
            gigId
          );
          setGigDetails(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };
    fetchGigDetails();
  }, [authHeader, gigId]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const initialDescription = gigDetails ? gigDetails.description : "";

  const handleDescriptionClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  const history = useNavigate();
  const handleResumeClick = async (
    gigId: any,
    gigTitle: string,
    category: string
  ) => {
    if (authHeader) {
      try {
        const response: any = await assignToUser(gigId, authHeader);
        if (response.data.cause && response.data.cause === "endOfTasks") {
          handleSnackbar(
            "info",
            "No more tasks avaialble at the moment, try again later."
          );
        } else {
          history(`/task/vendorDetails/${gigId}`, {
            state: { gigTitle: gigTitle, category: category },
          });
        }
      } catch (error) {
        console.error(`Error assigning gigId ${gigId} to gig seeker:`, error);
      }
    }
  };

  const handleCopyClick = () => {
    const urlToCopy = inviteLink;
    const tempInput = document.createElement("input");
    tempInput.value = urlToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    handleSnackbar("success", "Link copied successfully.");
  };

  const handleWhatsAppShare = async () => {
    const urlToShare = inviteLink;
    try {
      await navigator.clipboard.writeText(urlToShare);
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        urlToShare
      )}`;
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!supervisor) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container
      sx={{
        minHeight: "100px",
        backgroundColor: "Background.paper",
        marginTop: "30px",
        marginBottom: "50px",
      }}
    >
      <SetUserAddressPopup
        openDialog={openAddressDialog}
        onClose={() => handleDialogClose("address")}
      />
      {gigDetails ? (
        <>
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Grid item xs={12} sm={8}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "-10px",
                }}
              >
                {gigDetails?.companyLogo ? (
                  <img
                    src={gigDetails.companyLogo}
                    alt="company Logo"
                    style={{
                      width: "132px",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <WorkTwoToned
                    fontSize="large"
                    color="primary"
                    sx={{ margin: "5px", height: "60px", width: "60px" }}
                  />
                )}
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      lineHeight: "1.2",
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    {gigDetails.gigTitle}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      fontFamily: "Inter, sans-serif",
                      marginTop: "5px",
                      "@media (min-width: 600px)": {
                        flexDirection: "row",
                      },
                    }}
                  >
                    <Typography>at {gigDetails.companyName}</Typography>
                  </Box>
                  <Chip
                    size="medium"
                    label={"ONGOING"}
                    sx={{
                      color: "#F98C0C",
                      backgroundColor: "#FFEBCE",
                      borderRadius: "15px",
                      fontSize: "12px",
                      marginRight: "5px",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {(!matchMobileView && (
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {userRole === userRoles.supervisor && (
                      <>
                        <Button
                          onClick={() =>
                            history(`/manage-subordinates/${gigId}`)
                          }
                          variant="outlined"
                          color="primary"
                          sx={{
                            display: "flex",
                            margin: "15px",
                            textTransform: "none",
                          }}
                        >
                          Manage Team
                        </Button>
                      </>
                    )}
                    {pageMode !== "readonly" && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          width: "80px",
                          height: "35px",
                          marginLeft: "auto",
                          marginTop: "16px",
                        }}
                        onClick={() => {
                          if (userAddress.addressLine) {
                            handleResumeClick(
                              gigId,
                              gigDetails.gigTitle,
                              gigDetails.category
                            );
                          } else {
                            setAddressError(true);
                          }
                        }}
                      >
                        START
                      </Button>
                    )}
                  </div>
                </Box>
              </Grid>
            )) ||
              (matchMobileView && (
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    padding: "5px",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {userRole === userRoles.supervisor && (
                    <Button
                      onClick={() => history(`/manage-subordinates/${gigId}`)}
                      variant="outlined"
                      color="primary"
                      sx={{
                        display: "flex",
                        margin: "15px",
                        textTransform: "none",
                        fontSize: "16px",
                      }}
                    >
                      Manage Team
                    </Button>
                  )}
                  {pageMode !== "readonly" && (
                    <CustomButton
                      tag="Dashboard/Start"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        height: "40px",
                        padding: "15px",
                        width: "50%",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        if (userAddress.addressLine) {
                          handleResumeClick(
                            gigId,
                            gigDetails.gigTitle,
                            gigDetails.category
                          );
                        } else {
                          setAddressError(true);
                        }
                      }}
                    >
                      START
                    </CustomButton>
                  )}
                </div>
              ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  padding: matchMobileView ? "10px" : "none",
                  backgroundColor: "background.default",
                  borderRadius: "8px",
                  border: matchMobileView ? "2px solid #d4d4d4" : "none",
                  marginTop: "10px",
                }}
              >
                {userAddress && userAddress.addressLine ? (
                  <>
                    {userAddress.addressLine
                      .split("\n")
                      .map((line: string, index: number, array: string[]) => (
                        <React.Fragment key={index}>
                          <Typography>
                            <strong>Saved Address:</strong>
                            <br />
                            {line}
                            {index === array.length - 1 && (
                              <Button
                                color="primary"
                                size="small"
                                sx={{
                                  display: "inline-flex",
                                  padding: "1px",
                                  marginLeft: "5px",
                                  marginTop: "4px",
                                  textTransform: "none",
                                  marginBottom:
                                    index === array.length - 1 ? "5px" : "0",
                                }}
                                onClick={() => {
                                  setOpenAddressDialog(true);
                                }}
                              >
                                Edit Address
                                <EditIcon
                                  sx={{
                                    fontSize: "12px",
                                    marginLeft: "3px",
                                  }}
                                />
                              </Button>
                            )}
                          </Typography>
                        </React.Fragment>
                      ))}
                  </>
                ) : (
                  <>
                    <Typography>
                      <strong>Saved Address:</strong>
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "#FFEEC3",
                        display: "flex",
                        alignItems: "center",
                        padding: "2px",
                        borderBottom: "2px solid #FFA500",
                        width: "fit-content",
                        margin: "10px",
                      }}
                    >
                      <WarningAmberIcon sx={{ color: "#FCAA23" }} />
                      <Typography sx={{ color: "#FF8C00", marginLeft: "8px" }}>
                        No address saved, please add the address so that it will
                        help in task allocation.
                      </Typography>
                    </Box>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      sx={{
                        display: "inline-flex",
                        justifyContent: "flex-end",
                        marginBottom: "5px",
                        marginTop: "3px",
                      }}
                      onClick={() => {
                        setOpenAddressDialog(true);
                      }}
                    >
                      <AddIcon
                        sx={{
                          fontSize: "18px",
                          marginRight: "3px",
                          marginTop: "2px",
                        }}
                      />
                      Add Address
                    </Button>
                    {addressError && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "red",
                        }}
                      >
                        <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                        <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                          Please save address to continue
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Box>
              {userRole !== userRoles.seeker && (
                <Box
                  sx={{
                    border: "2px solid #d4d4d4",
                    padding: "10px",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="h6">Team Invitation Link:</Typography>
                  <div
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                  >
                    <CustomButton
                      tag="CopyLinkOfGigInvitation"
                      startIcon={<LinkIcon />}
                      onClick={() => {
                        handleCopyClick();
                      }}
                      sx={{ textTransform: "none", margin: "5px" }}
                      variant="outlined"
                      disabled={!supervisor}
                    >
                      Copy Gig Invitation Link
                    </CustomButton>
                    <CustomButton
                      onClick={handleWhatsAppShare}
                      sx={{ textTransform: "none", margin: "5px" }}
                      variant="outlined"
                      disabled={!supervisor}
                    >
                      <WhatsAppIcon sx={{ color: "green" }} /> Share on whatsapp
                    </CustomButton>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography sx={{ p: 1, backgroundColor: "white" }}>
                        You need supervisor role
                        <br /> to access this.
                      </Typography>
                    </Popover>
                  </div>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper
                elevation={0}
                sx={{
                  padding: "10px",
                  backgroundColor: "background.default",
                  borderRadius: "8px",
                  border: "2px solid #d4d4d4",
                  marginTop: "10px",
                }}
              >
                {gigDetails && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <MultipleCardsComponent gigId={gigId} />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          fontFamily: "inter, sans-serif",
                          width: "35px",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {gigDetails.totalVendorsCompletedByUser}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: "14px",
                          fontFamily: "inter, sans-serif",
                          height: "auto",
                          textAlign: "center",
                        }}
                      >
                        Total Completed
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          fontFamily: "inter, sans-serif",
                          width: "35px",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#22bb33",
                        }}
                      >
                        ₹{gigDetails.earned}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: "14px",
                          fontFamily: "inter, sans-serif",
                          height: "auto",
                          textAlign: "center",
                        }}
                      >
                        Total Amount Earned
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          fontFamily: "inter, sans-serif",
                          width: "35px",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {gigDetails.minCallsPerDay}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: "14px",
                          fontFamily: "inter, sans-serif",
                          height: "auto",
                          textAlign: "center",
                        }}
                      >
                        Minimum Tasks <br /> Per Day
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ minHeight: "450px", marginTop: "20px" }}>
            <Box style={{ flex: "0 0 50%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong> Category:</strong> {gigDetails.category}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Site:</strong> {gigDetails.site}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Gig Start Date: </strong>
                    {gigDetails?.gigStartDate
                      ? formatDate(gigDetails.gigStartDate)
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Gig End Date: </strong>
                    {gigDetails?.gigEndDate
                      ? formatDate(gigDetails.gigEndDate)
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong> Language: </strong>
                    {gigDetails.communicationLanguages}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Requirements:</strong> {capabilityNames}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Working Hours: </strong> 10:00 am to 05:00 pm
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box data-color-mode="light" sx={{ textAlign: "justify" }}>
                    <MDEditor.Markdown
                      source={
                        showFullDescription || initialDescription.length <= 500
                          ? initialDescription
                          : `${initialDescription.slice(0, 500)}...`
                      }
                    />
                    {initialDescription.length > 500 && (
                      <span>
                        <CustomButton
                          tag="Dashboard/ReadMore"
                          variant="text"
                          color="primary"
                          onClick={handleDescriptionClick}
                          style={{ cursor: "pointer" }}
                        >
                          {showFullDescription ? "Show Less" : "Read More"}
                        </CustomButton>
                      </span>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ minHeight: "250px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <CircularProgress color="primary" size={50} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography>
              Please wait while we fetch your gig details...
            </Typography>
          </Box>
        </Box>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default GigStatus;
