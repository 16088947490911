import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { savePaymentMethod } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import CustomLoading from "../custom/Loading";
import theme from "../../theme";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { validateName } from "../validators/ValidateName";

interface UPIFormProps {
  onCloseDialog: () => void;
  fetchUserUPIData: () => void;
}

const UPIForm: React.FC<UPIFormProps> = ({
  onCloseDialog,
  fetchUserUPIData,
}) => {
  const [name, setName] = useState<string>("");
  const [upiId, setUPIId] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [upiIdError, setUPIIdError] = useState<string>("");
  const { authHeader } = useAuthContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const snackbar = (message: string, severity: Severity) => {
    setOpenSnackbar(true);
    setSnackbarData({ message, severity });
  };
  const validateUPIId = (upiId: string): string => {
    if (!upiId) {
      return "UPI ID is required";
    }
    // Basic UPI ID validation (e.g., contains '@')
    if (!upiId.includes("@")) {
      return "Invalid UPI ID";
    }
    return "";
  };
  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        let hasError = false; // Flag to track if any validation error occurred

        if (validateName(name)) {
          setNameError("Name is required and must be more than 3 characters");
          hasError = true;
        } else {
          setNameError(""); // Clear the error message
        }

        if (validateUPIId(upiId)) {
          setUPIIdError("UPI ID is required and must be valid");
          hasError = true;
        } else {
          setUPIIdError(""); // Clear the error message
        }

        if (hasError) {
          setLoading(false);
          return;
        }

        // Submit the form
        const formData = {
          name: name,
          upiId: upiId,
          type: "upi",
        };

        try {
          if (authHeader) {
            const response: any = await savePaymentMethod(formData, authHeader);
            if (
              response.data.message === "payment method added successfully!"
            ) {
              snackbar("UPI ID added successfully", "success");
            }
            onCloseDialog();
            fetchUserUPIData();
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        setLoading(false);
      }
    },
    [fetchUserUPIData, name, upiId, setLoading, onCloseDialog, authHeader]
  );

  const handleChangeName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setName(value);
    const error = validateName(value);
    setNameError(error);
  };

  const handleChangeUPIId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setUPIId(value);
    const error = validateUPIId(value);
    setUPIIdError(error);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        {isLoading ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "200px",
              minWidth: "400px",
            }}
          >
            <CustomLoading />
          </Container>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <GlobalSnackbar
                open={openSnackbar}
                message={snackbarData.message}
                onClose={handleCloseSnackbar}
                severity={snackbarData.severity}
                vertical={"top"}
                horizontal={"center"}
              />
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  fullWidth
                  value={name}
                  onChange={handleChangeName}
                  error={!!nameError}
                  helperText={
                    nameError
                      ? nameError
                      : "Enter your name"
                  }
                  autoComplete="name"
                  InputProps={{
                    startAdornment: (
                      <AccountCircleIcon
                        sx={{ marginRight: 1, color: "gray" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="UPI ID"
                  fullWidth
                  value={upiId}
                  onChange={handleChangeUPIId}
                  error={!!upiIdError}
                  helperText={
                    upiIdError
                      ? upiIdError
                      : "Enter your UPI ID"
                  }
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <AccountCircleIcon
                        sx={{ marginRight: 1, color: "gray" }}
                      />
                    ),
                  }}
                />
              </Grid>
              {!isMobile && (
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              )}

              {isMobile && (
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 5px",
                    backgroundColor: "white",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                    sx={{
                      padding: "5px",
                      marginLeft: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Grid>
          </form>
        )}
      </Box>
    </Container>
  );
};

export default UPIForm;