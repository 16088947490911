import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  LinearProgressProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  getUserAddress,
  getWalletCredentials,
  getPaymentMethods,
  getUserCapabilityList,
  Capability,
} from "@gogig-in/gogig-clients";
import theme from "../../theme";
import ProfileStatusComponent from "./ProfileStatusComponent";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const ProfileDetailsCheckComponent = () => {
  const [progress, setProgress] = useState(10);
  const [profileData, setProfileData] = useState(false);
  const { userDetails, authHeader, isAuthenticated } = useAuthContext();
  const [openComponent, setOpenComponent] = useState<boolean>(false);
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(true);
  const [addressData, setAddressData] = useState(false);
  const [bankData, setBankData] = useState(false);
  const [upiData, setUpiData] = useState<boolean>(false);
  const [documentsData, setDocumentsData] = useState(false);
  const [skillsData, setSkillsData] = useState(false);
  const [userCapabilities, setUserCapabilities] = useState<Capability[]>([]);
  const [loading, setLoading] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (userDetails?.firstName && userDetails.emailId) {
      setProfileData(true);
    }
  }, [userDetails]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader && isAuthenticated) {
        const [
          addressResponse,
          documentResponse,
          bankResponse,
          capabilityResponse,
        ]: any = await Promise.all([
          getUserAddress(authHeader).catch((error) => {
            if (error.response && error.response.status === 404) {
              return { data: { addressLine: null }, status: 404 }; // Handle 404 specifically
            }
            throw error; // Rethrow other errors
          }),
          getWalletCredentials(authHeader),
          getPaymentMethods(authHeader),
          getUserCapabilityList(authHeader),
        ]);

        setAddressData(!!addressResponse.data?.addressLine);
        const documents = documentResponse.data[0];
        setDocumentsData(
          !!(documents?.aadhaarCardNumber || documents?.panCardNumber)
        );
        const bankDetails = bankResponse.data;
        setBankData(
          bankDetails.some(
            (bank: any) => bank.accountNo && bank.accountNo !== ""
          )
        );
        const hasUpiId = bankDetails.some(
          (bank: any) => bank.upiId && bank.upiId !== null
        );
        setUpiData(hasUpiId);
        setUserCapabilities(capabilityResponse);
        setSkillsData(!!capabilityResponse.length);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [authHeader, isAuthenticated]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleComponentOpen = () => {
    setOpenComponent(true);
  };

  const handleComponentClose = () => {
    if (openComponent) {
      setOpenComponent(false);
    } else {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    // Calculate progress percentage based on the number of completed data items
    const completedItems = [
      profileData,
      addressData,
      documentsData,
      bankData,
      upiData,
      skillsData,
    ].filter(Boolean);
    const totalItems = 6; // Total number of data items
    const newProgress = (completedItems.length / totalItems) * 100;
    setProgress(newProgress);
  }, [profileData, addressData, documentsData, bankData, upiData, skillsData]);

  const profileIncomplete =
    !addressData ||
    !bankData ||
    !upiData ||
    !documentsData ||
    !skillsData ||
    !userCapabilities;

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profileIncomplete || !isComponentVisible || !isAuthenticated) {
    return null;
  }

  return (
    <React.Fragment>
      <Box
        textAlign={"center"}
        sx={{
          my: 5,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Incomplete Profile Details
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            padding: 2,
          }}
        >
          Hey {userDetails?.firstName} {userDetails?.lastName}, it seems like
          your profile is not complete yet. To discover relevant gigs, make sure
          to fill in all your profile details.
        </Typography>
        <Box
          sx={{
            padding: 2,
            width: isDesktop ? "50%" : isMobile ? "75%" : "100%",
            margin: "0 auto",
          }}
        >
          <LinearProgressWithLabel value={progress} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            textAlign: "center",
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={handleComponentClose}>
            Cancel
          </Button>
          {!openComponent && (
            <Button variant="contained" onClick={handleComponentOpen}>
              Complete Profile
            </Button>
          )}
        </Box>
        {openComponent && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 5,
              marginLeft: 2,
              marginRight: 4,
            }}
          >
            <Box
              sx={{
                maxWidth: "760px",
                width: "100%",
              }}
            >
              <ProfileStatusComponent onDataUpdate={fetchData} />
            </Box>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ProfileDetailsCheckComponent;
