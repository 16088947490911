import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import theme from "../../theme";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  getPaymentMethods,
  removePaymentMethod,
} from "@gogig-in/gogig-clients";
import { setDefault } from "@gogig-in/gogig-clients";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import SkeletonModal from "../skeleton/SkeletonModal";
import AlertDialog from "../custom/AlertDialog";
import UPIDialog from "./UPIDialog"; // Create this component for adding/editing UPI details

export interface UPIData {
  paymentMethodId: string;
  userId?: string;
  type?: string;
  name: string;
  upiId: string;
  isDefault?: number;
}

export interface UPIDialogProps {
  open: boolean;
  onClose: () => void;
  upiData: UPIData[];
  onDataAdd: () => void;
}

const ShowUserUPIData: React.FC<UPIDialogProps> = ({
  open,
  onClose,
  upiData,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { authHeader } = useAuthContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openUPIForm, setOpenUPIForm] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const [alertDialog, setAlertDialog] = useState<boolean>(false);
  const [selectedUPIId, setSelectedUPIId] = useState<string>("");

  const handleOpenDialog = (paymentMethodId: string) => {
    setSelectedUPIId(paymentMethodId);
    setAlertDialog(true);
  };

  const handleAgree = () => {
    if (selectedUPIId) {
      handleDeleteUPIAccount(selectedUPIId);
    }
    setAlertDialog(false);
  };

  const handleCloseDialog = () => {
    setAlertDialog(false);
  };

  const snackbar = (message: string, severity: Severity) => {
    setOpenSnackbar(true);
    setSnackbarData({ message, severity });
  };

  const fetchUserDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await getPaymentMethods(authHeader);
        const data: UPIData[] = response.data;
        // Find the default UPI
        let defaultUPI = data.find((upi) => upi.isDefault === 1);
        if (!defaultUPI && data.length > 0) {
          defaultUPI = data[0];
          defaultUPI.isDefault = 1; // Set isDefault to 1 for the first UPI account
          try {
            await setDefault(defaultUPI.paymentMethodId, authHeader);
          } catch (error) {
            console.error("Something went wrong", error);
          }
        }
      }
    } catch (error) {
      console.error("error during getPaymentMethods", error);
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  useEffect(() => {
    if (authHeader) {
      fetchUserDocument();
    }
  }, [authHeader, fetchUserDocument]);

  const handleAddUPIData = async () => {
    setOpenUPIForm(true);
    await fetchUserDocument();
  };

  const handleSaveDefaultUPIAccount = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await setDefault(paymentMethodId, authHeader); // Call the saveDefault API
        if (response.data.message === "Payment method default changed") {
          snackbar("Default UPI account changed", "success");
          await fetchUserDocument();
          setLoading(false);
          onClose();
        }
      }
    } catch (error) {
      console.error("Error saving default UPI account:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDeleteConfirmation = async (paymentMethodId: string) => {
    handleOpenDialog(paymentMethodId);
  };

  const handleDeleteUPIAccount = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await removePaymentMethod(
          paymentMethodId,
          authHeader
        );
        if (response.data.message === "Payment Method removed") {
          snackbar("Removed your UPI account successfully", "success");
          setLoading(false);
          await fetchUserDocument();
          onClose();
        }
      }
    } catch (error) {
      console.error("Error deleting UPI account:", { error });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseUPIForm = async () => {
    setOpenUPIForm(false);
    onClose();
    // Refetch UPI data after closing the UPI form
    await fetchUserDocument();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
        <GlobalSnackbar
          open={openSnackbar}
          message={snackbarData.message}
          onClose={handleCloseSnackbar}
          severity={snackbarData.severity}
          vertical={"top"}
          horizontal={"center"}
        />
        <DialogTitle>
          UPI Details
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "500px",
              minWidth: "400px",
            }}
          >
            <SkeletonModal skeletonType="default" />
          </Container>
        ) : (
          <Container
            sx={{
              minWidth: isMobile ? "" : "400px",
              minHeight: isMobile ? "" : "500px",
            }}
          >
            <DialogContent>
              {upiData
                .filter((bank) => bank.type === "upi")
                .sort((a, b) => (b.isDefault ?? 0) - (a.isDefault ?? 0))
                .map((upi) => (
                  <Card
                    key={upi.paymentMethodId}
                    sx={{
                      marginBottom: 1,
                      border: upi.isDefault === 1 ? "2px solid green" : "",
                      position: "relative",
                      boxShadow: 4,
                      borderRadius: 2,
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    <CardContent>
                      {upi.isDefault === 1 && (
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "green",
                            color: "white",
                            padding: "2px 15px",
                            borderRadius: "50px",
                            fontSize: "12px",
                          }}
                        >
                          Primary
                        </Typography>
                      )}
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Name :{" "}
                            <span
                              style={{
                                fontWeight: 400,
                              }}
                            >
                              {upi.name}
                            </span>
                          </Typography>
                          <Typography variant="h6" gutterBottom>
                            UPI ID:{" "}
                            <span
                              style={{
                                fontWeight: 400,
                              }}
                            >
                              {upi.upiId}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Grid item xs={2}>
                          {upi.isDefault !== 1 && ( // Render button only if not default
                            <Button
                              onClick={() =>
                                handleSaveDefaultUPIAccount(upi.paymentMethodId)
                              }
                              color="primary"
                            >
                              Set Default
                            </Button>
                          )}
                        </Grid>
                        <Button
                          onClick={() =>
                            handleDeleteConfirmation(upi.paymentMethodId)
                          }
                          color="error"
                        >
                          <DeleteIcon /> REMOVE
                        </Button>
                      </DialogActions>
                    </CardContent>
                  </Card>
                ))}
              {upiData.length === 1 && (
                <>
                  <Button variant="contained" onClick={handleAddUPIData}>
                    Add UPI Details
                  </Button>
                </>
              )}
            </DialogContent>
            <AlertDialog
              open={alertDialog}
              title={"Are you sure you want to delete"}
              content=""
              onDisagree={handleCloseDialog}
              onAgree={handleAgree}
              onClose={handleCloseDialog}
            />
          </Container>
        )}
      </Dialog>
      {openUPIForm && (
        <UPIDialog
          open={open}
          onClose={onClose}
          isLoading={isLoading}
          onCloseUPIForm={handleCloseUPIForm}
          fetchUserUPIData={fetchUserDocument}
        />
      )}
    </React.Fragment>
  );
};

export default ShowUserUPIData;
