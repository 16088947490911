import React, { useRef, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { getUserAddress, createProfile } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";
import AlertDialog from "../custom/AlertDialog";
import ClearIcon from "@mui/icons-material/Clear";
import CustomModal from "../custom/CustomModal";
import { GoogleMapsApiKey } from "../../clients/baseUrls";

interface addressProps {
  openDialog: boolean;
  onClose: () => void;
}

declare global {
  interface Window {
    google: any;
    initAutocomplete: () => void;
  }
}

const SetUserAddressPopup: React.FC<addressProps> = ({
  openDialog,
  onClose,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [cityName, setCityName] = useState<string>("");
  const [areaName, setAreaName] = useState<string>("");
  const [zipCode, setZipCode] = useState<number | string>();
  const [userAddress, setUserAddress] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const { authHeader } = useAuthContext();
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const autocompleteRef = useRef<any>(null);
  const scriptLoadedRef = useRef<boolean>(false);

  // Add a style to ensure pac-container is above everything else
  useEffect(() => {
    // Add a style element to the document to ensure pac-container is above everything
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .pac-container {
        z-index: 10000 !important;
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      // Clean up the style element when component unmounts
      if (document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);

  // Define a global callback function for the script to use
  window.initAutocomplete = () => {
    if (inputRef.current) {
      try {
        const options = {
          types: ['address'],
          componentRestrictions: { country: 'in' },
        };
        
        autocompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        );
        
        // Add event listener
        autocompleteRef.current.addListener("place_changed", () => {
          const place = autocompleteRef.current.getPlace();
          if (place && place.formatted_address) {
            setSelectedAddress(place.formatted_address);
            
            if (place.geometry && place.geometry.location) {
              setCoordinates({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }
            
            if (place.address_components) {
              place.address_components.forEach((component: any) => {
                const types = component.types;
                
                if (types.includes("locality")) {
                  setCityName(component.long_name);
                }
                
                if (types.includes("sublocality_level_1")) {
                  setAreaName(component.long_name);
                }
                
                if (types.includes("postal_code")) {
                  setZipCode(component.long_name);
                }
              });
            }
          }
        });
        
        console.log("Google Places Autocomplete initialized successfully");
      } catch (error) {
        console.error("Error initializing Places Autocomplete", error);
      }
    }
  };

  useEffect(() => {
    // Define a MutationObserver to watch for any pac-container elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const pacContainers = document.querySelectorAll('.pac-container');
          pacContainers.forEach((container) => {
            const containerElement = container as HTMLElement;
            containerElement.style.zIndex = '10000';
          });
        }
      });
    });

    // Start observing the document body for changes
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer when component unmounts
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (scriptLoadedRef.current) {
        // If script is already loaded, just initialize autocomplete
        if (window.google && window.google.maps && window.google.maps.places) {
          window.initAutocomplete();
        }
        return;
      }
      
      try {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsApiKey}&libraries=places&callback=initAutocomplete`;
        script.async = true;
        script.defer = true;
        script.onerror = () => console.error("Google Maps script failed to load");
        
        document.head.appendChild(script);
        scriptLoadedRef.current = true;
      } catch (error) {
        console.error("Error loading Google Maps script", error);
      }
    };

    if (openDialog) {
      loadGoogleMapsScript();
    }
    
    return () => {
      // Cleanup
      if (autocompleteRef.current) {
        window.google?.maps?.event?.clearInstanceListeners?.(autocompleteRef.current);
      }
    };
  }, [openDialog]);

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        if (authHeader) {
          const response = await getUserAddress(authHeader);
          setUserAddress(response.data);
        }
      } catch (error: any) {
        if (error.status === 404 || error.errorCause === "detailsNotFound")
          console.error("Error fetching user address", error);
      }
    };

    if (openDialog) {
      fetchUserAddress();
    }
  }, [authHeader, openDialog]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    if (coordinates) {
      try {
        const formValues = {
          addressLine: selectedAddress || "",
          latitude: coordinates?.lat?.toString() || "",
          longitude: coordinates?.lng?.toString() || "",
          workLocation: cityName || "",
          area: areaName || "",
          pincode: zipCode || "",
        };

        if (authHeader) {
          try {
            const response: any = await createProfile(
              formValues,
              authHeader,
              "PATCH"
            );

            const message = response.data;
            if (message === "Profile details saved successfully!!") {
              setSnackbarOpen(true);
              setTimeout(() => {
                onClose();
                handleClose("main");
                handleClose("confirm");
              }, 2000);
            }
          } catch (err) {
            console.error("Error during createProfile", err);
          }
        }
      } catch (error) {
        console.error("Error saving profile details", error);
      }
    }
  };

  const clearText = () => {
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear the input value
      setSelectedAddress(null);
      setCoordinates(null);
      setCityName("");
      setAreaName("");
      setZipCode("");
      inputRef.current.focus(); // Keep focus on the input field after clearing
    }
  };

  const handleClose = (dialogType: "main" | "confirm") => {
    if (dialogType === "main") {
      onClose();
    } else if (dialogType === "confirm") {
      setConfirmDialog(false);
    }
  };

  const handleRequestPermission = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCoordinates({ lat, lng });

          if (!window.google || !window.google.maps) {
            console.error("Google Maps API not loaded yet");
            setLoading(false);
            return;
          }

          const geocoder = new window.google.maps.Geocoder();
          try {
            const response = await geocoder.geocode({ location: { lat, lng } });

            if (response.results.length > 0) {
              const mostAccurateResult = response.results.find(
                (result: any) => result.geometry.location_type === "ROOFTOP"
              ) || response.results[0]; // Fallback to the first result

              const address = mostAccurateResult.formatted_address;
              let city = "";
              let area = "";
              let postalCode = "";

              mostAccurateResult.address_components.forEach((component: any) => {
                if (component.types.includes("locality")) {
                  city = component.long_name;
                }
                if (component.types.includes("sublocality_level_1")) {
                  area = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                  postalCode = component.long_name;
                }
              });

              // Update all states together
              setSelectedAddress(address);
              if (inputRef.current) {
                inputRef.current.value = address;
              }
              setCityName(city);
              setAreaName(area);
              setZipCode(postalCode);
            }
          } catch (error) {
            console.error("Error in geocoding", error);
          }
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching geolocation", error);
          setLocationPermissionDenied(true);
          setLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };
  
  const handleConfirmLocation = () => {
    if (selectedAddress || coordinates) {
      setConfirmDialog(true);
    } else {
      alert(`Please select your location`);
    }
  };

  return (
    <CustomModal
      open={openDialog}
      onClose={onClose}
      title="Location details"
      isLoading={false}
      primaryActionOnclick={handleConfirmLocation}
      primaryActionText="Confirm"
      secondaryActionOnclick={() => handleClose("main")}
      secondaryActionText="Cancel"
    >
      <Box>
        <Typography>
          {selectedAddress ? (
            <>
              <Typography component="span" fontWeight="bold">
                Current Location:{" "}
              </Typography>
              {selectedAddress}
            </>
          ) : (
            ""
          )}
        </Typography>
        <Box textAlign={"center"}>
          <Button
            onClick={handleRequestPermission}
            color="primary"
            variant="text"
            sx={{ marginTop: "10px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Use Current Location"}
          </Button>
        </Box>
        <Typography textAlign="center" my={2}>
          Or
        </Typography>
        <Box>
          {userAddress && userAddress.addressLine && (
            <>
              <Typography>
                <strong>Saved Address:</strong>{" "}
                {userAddress.addressLine}
              </Typography>
            </>
          )}
        </Box>
        <Box position="relative">
          <TextField
            variant="standard"
            inputRef={inputRef}
            label="Search Place"
            name="addressLine"
            placeholder="Type to search locations"
            size="small"
            fullWidth
            sx={{ marginTop: "10px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear"
                    onClick={clearText}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Snackbar open={snackbarOpen} autoHideDuration={3000}>
          <Alert onClose={handleSnackbarClose} severity="success">
            Address Updated Successfully!
          </Alert>
        </Snackbar>
        {locationPermissionDenied && (
          <Typography color="error" sx={{ marginTop: "10px" }}>
            Location permission is denied. Please enable location services in
            your browser settings.
          </Typography>
        )}
        <AlertDialog
          open={confirmDialog}
          title={"Location confirmation"}
          content={`Are you sure this is your location ${
            areaName && cityName ? `${areaName}, ${cityName}` : selectedAddress
          }?`}
          onDisagree={() => handleClose("confirm")}
          onAgree={handleSubmit}
          onClose={() => handleClose("confirm")}
        />
      </Box>
    </CustomModal>
  );
};

export default SetUserAddressPopup;