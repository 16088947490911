import React from "react";
import { Box, Grid } from "@mui/material";
import Container from "@mui/system/Container/Container";
import UserProfileData from "../components/profile/UserProfileData";
import UserCapabilities from "../components/profile/UserCapabilities";
import UserBankData from "../components/bankDetails/UserBankData";
import UserDocumentData from "../components/userDocuments/UserDocumentData";
import UserAddressData from "../components/profile/UserAddressData";
import UserUPIData from "../components/bankDetails/UserUpiData";

const UserData: React.FC = () => {
  return (
    <Grid>
      <Box marginTop={5}>
        <Container>
          <Box textAlign="center" mb={2} className="profile-page">
            <UserProfileData />
            <UserAddressData />
            <UserDocumentData />
            <UserBankData />
            <UserUPIData/>
            <UserCapabilities />
          </Box>
        </Container>
      </Box>
    </Grid>
  );
};

export default UserData;
