import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Skeleton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DescriptionIcon from "@mui/icons-material/Description";
import BuildIcon from "@mui/icons-material/Build";
import { useAuthContext } from "../../providers/AuthProvider";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import {
  Capability,
  getPaymentMethods,
  getUserAddress,
  getUserCapabilityList,
  getWalletCredentials,
} from "@gogig-in/gogig-clients";
import EditProfilePopup from "../ProfileEditComponents/EditProfilePopup";
import BankDialog from "../bankDetails/BankDialog";
import EditCapabilitiesPopUp from "../ProfileEditComponents/EditCapabilitiesPopUp";
import UploadDocumentsPopup from "../userDocuments/UploadDocumentsPopup";
import SetUserAddressPopup from "../vendorDetail/SetUserAddressPopup";
import UPIDialog from "../bankDetails/UPIDialog";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";

const ProfileStatusComponent = ({
  onDataUpdate,
}: {
  onDataUpdate: () => void;
}) => {
  const [profileData, setProfileData] = useState(false);
  const [addressData, setAddressData] = useState(false);
  const [bankData, setBankData] = useState(false);
  const [upiData, setUpiData] = useState<boolean>(false);
  const [documentsData, setDocumentsData] = useState(false);
  const [skillsData, setSkillsData] = useState(false);
  const [loading, setLoading] = useState(false);
  const { authHeader, userDetails } = useAuthContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openDocDialog, setOpenDocDialog] = useState(false);
  const [openBankDialog, setOpenBankDialog] = useState(false);
  const [openUpiDialog, setOpenUPIDialog] = useState<boolean>(false);
  const [openSkillDialog, setOpenSkillDialog] = useState(false);
  const [userCapabilities, setUserCapabilities] = useState<Capability[]>([]);

  useEffect(() => {
    if (userDetails?.firstName && userDetails.emailId) {
      setProfileData(true);
    }
  }, [userDetails]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader) {
        const [
          addressResponse,
          documentResponse,
          bankResponse,
          capabilityResponse,
        ]: any = await Promise.all([
          getUserAddress(authHeader),
          getWalletCredentials(authHeader),
          getPaymentMethods(authHeader),
          getUserCapabilityList(authHeader),
        ]);

        setAddressData(!!addressResponse.data?.addressLine);
        const documents = documentResponse.data[0];
        setDocumentsData(
          !!(documents?.aadhaarCardNumber || documents?.panCardNumber)
        );
        setBankData(
          !!bankResponse.data.filter((b: any) => b.type === "bank").length
        );
        setUpiData(
          !!bankResponse.data.filter((b: any) => b.type === "upi").length
        );
        setUserCapabilities(capabilityResponse);
        setSkillsData(!!capabilityResponse.length);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDialogOpen = (type: string) => {
    switch (type) {
      case "profile":
        setOpenDialog(true);
        break;
      case "address":
        setOpenAddressDialog(true);
        break;
      case "document":
        setOpenDocDialog(true);
        break;
      case "bank":
        setOpenBankDialog(true);
        break;
      case "upi":
        setOpenUPIDialog(true);
        break;
      case "skill":
        setOpenSkillDialog(true);
        break;
      default:
        break;
    }
  };

  const handleDialogClose = (type: string) => {
    switch (type) {
      case "profile":
        setOpenDialog(false);
        fetchData(); // Refetch data after closing dialog
        break;
      case "address":
        setOpenAddressDialog(false);
        fetchData(); // Refetch data after closing dialog
        break;
      case "document":
        setOpenDocDialog(false);
        fetchData(); // Refetch data after closing dialog
        break;
      case "bank":
        setOpenBankDialog(false);
        fetchData(); // Refetch data after closing dialog
        break;
      case "upi":
        setOpenUPIDialog(false);
        fetchData(); // Refetch data after closing dialog
        break;
      case "skill":
        setOpenSkillDialog(false);
        fetchData(); // Refetch data after closing dialog
        break;
      default:
        break;
    }
    onDataUpdate();
  };

  const completionStatus = [
    {
      label: "Profile",
      data: profileData,
      dialogType: "profile",
      icon: <PersonIcon />,
    },
    {
      label: "Address",
      data: addressData,
      dialogType: "address",
      icon: <HomeIcon />,
    },
    {
      label: "Documents",
      data: documentsData,
      dialogType: "document",
      icon: <DescriptionIcon />,
    },
    {
      label: "Bank details",
      data: bankData,
      dialogType: "bank",
      icon: <CreditCardIcon />,
    },
    {
      label: "UPI Details",
      data: upiData,
      dialogType: "upi",
      icon: <AssuredWorkloadIcon />,
    },
    {
      label: "Skills & capabilities",
      data: skillsData,
      dialogType: "skill",
      icon: <BuildIcon />,
    },
  ];

  return (
    <React.Fragment>
      <EditProfilePopup
        open={openDialog}
        onClose={() => handleDialogClose("profile")}
      />
      <SetUserAddressPopup
        openDialog={openAddressDialog}
        onClose={() => handleDialogClose("address")}
      />
      <UploadDocumentsPopup
        open={openDocDialog}
        onClose={() => handleDialogClose("document")}
      />

      <EditCapabilitiesPopUp
        open={openSkillDialog}
        onClose={() => handleDialogClose("skill")}
        userSelectedCapabilities={userCapabilities}
      />
      <BankDialog
        fetchUserBankData={fetchData}
        open={openBankDialog}
        onClose={() => handleDialogClose("bank")}
        isLoading={loading}
        onCloseBankForm={() => handleDialogClose("bank")}
      />

      <UPIDialog
        open={openUpiDialog}
        onClose={() => handleDialogClose("upi")}
        isLoading={loading}
        onCloseUPIForm={() => handleDialogClose("upi")}
        fetchUserUPIData={fetchData}
      />

      <Paper
        sx={{
          width: "100%",
          height: "100%",
          padding: 1,
          maxHeight: "540px",
        }}
        elevation={4}
      >
        <List>
          {completionStatus.map(({ label, data, dialogType, icon }, index) => (
            <React.Fragment key={label}>
              {loading ? (
                <Skeleton variant="text" width="100%" height={60} />
              ) : (
                <ListItem
                  key={label}
                  sx={{
                    backgroundColor: data ? "#90EE90" : "transparent",
                    borderRadius: 2,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 35 }}>{icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      data ? `${label} is completed` : `Incomplete ${label}`
                    }
                  />
                  <ListItemSecondaryAction>
                    <ListItemButton
                      dense
                      alignItems="flex-start"
                      disabled={data}
                      onClick={() => handleDialogOpen(dialogType)}
                    >
                      {data ? <CheckIcon /> : <AddIcon />}
                    </ListItemButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              {index !== 4 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
};

export default ProfileStatusComponent;
