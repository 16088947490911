import {
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import { useAuthContext } from "../../providers/AuthProvider";
import theme from "../../theme";
import BankDetailsForm from "./BankDetailsForm";
import { getPaymentMethods } from "@gogig-in/gogig-clients";
import ShowUserBankData, { BankData } from "./ShowUserBankData";
import ProfileCard from "../profile/ProfileCard";
import CustomModal from "../custom/CustomModal";

const UserBankData = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [userBankData, showUserBankData] = useState<BankData[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { authHeader } = useAuthContext();

  const snackbar = (message: string, severity: Severity) => {
    setOpenSnackbar(true);
    setSnackbarData({ message, severity });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const fetchUserDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await getPaymentMethods(authHeader);
        const data: BankData[] = response.data;
        showUserBankData(data);
      }
    } catch (error: any) {
      if (error.status === 401) {
        snackbar("Unauthorized access. Please log in again", "error");
      } else {
        console.error(
          "An error occurred while fetching user capabilities:",
          error
        );
      }
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  const handleClose = useCallback(() => {
    setOpenSnackbar(false);
    setOpenDialog(false);
    fetchUserDocument(); // Fetch data after snackbar is closed
  }, [fetchUserDocument]);

  useEffect(() => {
    if (authHeader) {
      fetchUserDocument();
    }
  }, [authHeader, fetchUserDocument]);

  const handleDataAdd = async () => {
    await fetchUserDocument();
    setOpenDialog(false);
  };

  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        severity={snackbarData.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <CustomModal
        open={openDialog}
        onClose={handleClose}
        title="Bank Details"
        isLoading={false}
      >
        {userBankData.length === 0 ? (
          <BankDetailsForm
            onCloseDialog={handleClose}
            fetchUserBankData={fetchUserDocument}
          />
        ) : (
          <ShowUserBankData
            bankData={userBankData}
            open={openDialog}
            onClose={handleClose}
            onDataAdd={handleDataAdd}
          />
        )}
      </CustomModal>

      <ProfileCard
        title="Bank Details"
        editTooltipText="Edit Bank Details"
        onDialogOpen={handleDialogOpen}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          {userBankData.filter((b) => b.type === "bank").length === 0 ? (
            <Grid item xs={12} mt={2}>
              <Typography variant="h5" align="center">
                No Bank Account details available
              </Typography>
            </Grid>
          ) : (
            <>
              {userBankData
                .filter((bank) => bank.type === "bank")
                .sort((a, b) => (b.isDefault ?? 0) - (a.isDefault ?? 0))
                .map((account, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card
                      sx={{
                        marginRight: isMobile ? "" : "10px",
                        marginTop: isMobile ? "10px" : "",
                        padding: "10px",
                        border:
                          account.isDefault === 1 ? "2px solid green" : "",
                        position: "relative",
                        borderRadius: 2,
                      }}
                    >
                      {account.isDefault === 1 && (
                        <Chip
                          sx={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                          }}
                          color="success"
                          label="Primary"
                        />
                      )}
                      <CardContent>
                        <Typography sx={{ fontWeight: "600" }}>
                          Account holder name:{" "}
                          <span
                            style={{
                              fontWeight: "400",
                              display: isMobile ? "block" : "",
                            }}
                          >
                            {account.name}
                          </span>
                        </Typography>
                        <Typography sx={{ fontWeight: "600", marginY: "10px" }}>
                          Account number:{" "}
                          <span
                            style={{
                              fontWeight: "400",
                              display: isMobile ? "block" : "",
                            }}
                          >
                            {account.accountNo}
                          </span>
                        </Typography>
                        <Typography sx={{ fontWeight: "600" }}>
                          Bank IFSC code:{" "}
                          <span
                            style={{
                              fontWeight: "400",
                              display: isMobile ? "block" : "",
                            }}
                          >
                            {account.ifsc}
                          </span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </ProfileCard>
    </>
  );
};

export default UserBankData;
