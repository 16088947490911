import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import theme from "../../theme";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  getPaymentMethods,
  removePaymentMethod,
} from "@gogig-in/gogig-clients";
import { setDefault } from "@gogig-in/gogig-clients";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import SkeletonModal from "../skeleton/SkeletonModal";
import AlertDialog from "../custom/AlertDialog";
import BankDialog from "./BankDialog";

export interface BankData {
  paymentMethodId: string;
  userId?: string;
  type?: string;
  name: string;
  accountNo: string;
  ifsc: string;
  upiId?: string | null;
  isDefault?: number;
}

export interface BankDialogProps {
  open: boolean;
  onClose: () => void;
  bankData: BankData[];
  onDataAdd: () => void;
}

const ShowUserBankData: React.FC<BankDialogProps> = ({
  open,
  onClose,
  bankData,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { authHeader } = useAuthContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openBankForm, setOpenBankForm] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const [alertDialog, setAlertDialog] = useState<boolean>(false);
  const [selectedBankId, setSelectedBankId] = useState<string>("");

  const handleOpenDialog = (paymentMethodId: string) => {
    setSelectedBankId(paymentMethodId);
    setAlertDialog(true);
  };

  const handleAgree = () => {
    if (selectedBankId) {
      handleDeleteBankAccount(selectedBankId);
    }
    setAlertDialog(false);
  };

  const handleCloseDialog = () => {
    setAlertDialog(false);
  };

  const snackbar = (message: string, severity: Severity) => {
    setOpenSnackbar(true);
    setSnackbarData({ message, severity });
  };
  const fetchUserDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await getPaymentMethods(authHeader);
        const data: BankData[] = response.data;
        // Find the default bank
        let defaultBank = data.find((bank) => bank.isDefault === 1);
        if (!defaultBank && data.length > 0) {
          defaultBank = data[0];
          defaultBank.isDefault = 1; // Set isDefault to 1 for the first bank account
          try {
            await setDefault(defaultBank.paymentMethodId, authHeader);
          } catch (error) {
            console.error("Something went wrong", error);
          }
        }
      }
    } catch (error) {
      console.error("error during getPaymentMethods", error);
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  useEffect(() => {
    if (authHeader) {
      fetchUserDocument();
    }
  }, [authHeader, fetchUserDocument]);

  const handleAddBankData = async () => {
    setOpenBankForm(true);
    await fetchUserDocument();
  };

  const handleSaveDefaultBankAccount = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await setDefault(paymentMethodId, authHeader); // Call the saveDefault API
        if (response.data.message === "Payment method default changed") {
          snackbar("Default bank account changed", "success");
          await fetchUserDocument();
          setLoading(false);
          onClose();
        }
      }
    } catch (error) {
      console.error("Error saving default bank account:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDeleteConfirmation = async (paymentMethodId: string) => {
    handleOpenDialog(paymentMethodId);
  };

  const handleDeleteBankAccount = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await removePaymentMethod(
          paymentMethodId,
          authHeader
        );
        if (response.data.message === "Payment Method removed") {
          snackbar("Removed you bank account successfully", "success");
          setLoading(false);
          await fetchUserDocument();
          onClose();
        }
      }
    } catch (error) {
      console.error("Error deleting bank account:", { error });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseBankForm = async () => {
    setOpenBankForm(false);
    onClose();
    // Refetch bank data after closing the bank form
    await fetchUserDocument();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
        <GlobalSnackbar
          open={openSnackbar}
          message={snackbarData.message}
          onClose={handleCloseSnackbar}
          severity={snackbarData.severity}
          vertical={"top"}
          horizontal={"center"}
        />
        <DialogTitle>
          Bank Details
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "500px",
              minWidth: "400px",
            }}
          >
            <SkeletonModal skeletonType="default" />
          </Container>
        ) : (
          <Container
            sx={{
              minWidth: isMobile ? "" : "400px",
              minHeight: isMobile ? "" : "500px",
            }}
          >
            <DialogContent>
              {bankData
                .filter((bank) => bank.type === "bank")
                .sort((a, b) => (b.isDefault ?? 0) - (a.isDefault ?? 0))
                .map((bank) => (
                  <Card
                    key={bank.paymentMethodId}
                    sx={{
                      marginBottom: 1,
                      border: bank.isDefault === 1 ? "2px solid green" : "",
                      position: "relative",
                      boxShadow: 4,
                      borderRadius: 2,
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    <CardContent>
                      {bank.isDefault === 1 && (
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "green",
                            color: "white",
                            padding: "2px 15px",
                            borderRadius: "50px",
                            fontSize: "12px",
                          }}
                        >
                          Primary
                        </Typography>
                      )}
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Name :{" "}
                            <span
                              style={{
                                fontWeight: 400,
                              }}
                            >
                              {bank.name}
                            </span>
                          </Typography>
                          <Typography variant="h6" gutterBottom>
                            Account Number:{" "}
                            <span
                              style={{
                                fontWeight: 400,
                              }}
                            >
                              {bank.accountNo}
                            </span>
                          </Typography>
                          <Typography variant="h6">
                            IFSC Code:{" "}
                            <span
                              style={{
                                fontWeight: 400,
                              }}
                            >
                              {bank.ifsc}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Grid item xs={2}>
                          {bank.isDefault !== 1 && ( // Render button only if not default
                            <Button
                              onClick={() =>
                                handleSaveDefaultBankAccount(
                                  bank.paymentMethodId
                                )
                              }
                              color="primary"
                            >
                              Set Default
                            </Button>
                          )}
                        </Grid>
                        <Button
                          onClick={() =>
                            handleDeleteConfirmation(bank.paymentMethodId)
                          }
                          color="error"
                        >
                          <DeleteIcon /> REMOVE
                        </Button>
                      </DialogActions>
                    </CardContent>
                  </Card>
                ))}
              {bankData.length === 1 && (
                <>
                  <Button variant="contained" onClick={handleAddBankData}>
                    Add Bank Details
                  </Button>
                </>
              )}
            </DialogContent>
            <AlertDialog
              open={alertDialog}
              title={"Are you sure you want to delete"}
              content=""
              onDisagree={handleCloseDialog}
              onAgree={handleAgree}
              onClose={handleCloseDialog}
            />
          </Container>
        )}
      </Dialog>
      {openBankForm && (
        <BankDialog
          open={open}
          onClose={onClose}
          isLoading={isLoading}
          onCloseBankForm={handleCloseBankForm}
          fetchUserBankData={fetchUserDocument}
        />
      )}
    </React.Fragment>
  );
};

export default ShowUserBankData;
